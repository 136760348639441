module.exports = {
  siteMetadata: {
    author: 'Branden DuPont',
    title: `Milwaukee County District Attorney Dashboard`,
    description: `The Milwaukee County District Attorney's Office prosecutes violations of state laws and county ordinances and litigates child protection cases in the Milwaukee County Circuit Court. The District Attorney, an elected official, supervises all functions of the office. The District Attorney is assisted by seven deputy district attorneys, approximately 120 assistant district attorneys, and approximately 160 support staff, including victim-witness advocates, investigators, clerical professionals and crisis-response specialists.

    The mission of the Milwaukee County District Attorney's Office is to promote public safety through the fair and just prosecution of criminal offenses; to protect the health and welfare of children who are victims of child abuse and neglect; to advocate for justice for the victims of crimes; to safeguard the rule of law; and to promote citizens' participation in sustainable neighborhoods by treating all persons who come in contact with the criminal justice system with fairness, dignity and respect.`,
    navigation: [
      {
        items: [{ text: 'Home', link: '/' }],
      },
      {
        title: 'Explore Metrics',
        items: [
          { text: 'Referrals', link: '/referral' },
          { text: 'Charging', link: '/charge' },
          { text: 'Disposition', link: '/dispo' },
          { text: 'Sentencing', link: '/sentencing' }
        ],
      },
      {
        items: [{ text: 'Data Stories', link: '/blog' }],
      },
      {
        items: [{ text: 'Definitions', link: '/definition' }],
      },
      {
        items: [
          { text: 'About', link: '/about' },
        ],
      }
    ],
    secondaryLinks: [
      { text: 'Homepage', link: 'https://county.milwaukee.gov/EN/District-Attorney' },
      { text: 'Milwaukee Community Justice Council', link: 'https://www.milwaukee.gov/EN/MCJC' },
    ],

    // Search.gov configuration
    //
    // 1. Create an account with Search.gov https://search.usa.gov/signup
    // 2. Add a new site.
    // 3. Add your site/affiliate name here.
    searchgov: {
      endpoint: 'https://search.usa.gov', // You should not change this.
      affiliate: 'federalist-uswds-example', // replace this with your search.gov account
      access_key: 'xX1gtb2RcnLbIYkHAcB6IaTRr4ZfN-p16ofcyUebeko=', // This is placeholder. Not private.
      inline: true, // this renders the results on the same domain. Otherwise, it will render the results in the search.gov domain
    },
    dapAgency: 'GSA',
  },
  pathPrefix: process.env.BASEURL || '/',
  plugins: [`gatsby-plugin-sass`],
  plugins: [`gatsby-plugin-styled-components`],
  plugins: [
    `gatsby-plugin-sass`,
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: 'UA-163114241-2',
      },
    },
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `blog-posts`,
        path: `${__dirname}/src/blog-posts`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `documentation-pages`,
        path: `${__dirname}/src/documentation-pages`,
      },
    },`gatsby-transformer-csv`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `data`,
        path: `${__dirname}/src/`,
      }
    },
    `gatsby-transformer-remark`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#663399`,
        theme_color: `#663399`,
        display: `minimal-ui`,
        icon: `src/images/federalist-icon.png`, // This path is relative to the root of the site.
      },

      
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
};
