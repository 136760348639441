// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-charge-js": () => import("./../../../src/pages/charge.js" /* webpackChunkName: "component---src-pages-charge-js" */),
  "component---src-pages-dispo-js": () => import("./../../../src/pages/dispo.js" /* webpackChunkName: "component---src-pages-dispo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marijuana-convictions-js": () => import("./../../../src/pages/marijuana-convictions.js" /* webpackChunkName: "component---src-pages-marijuana-convictions-js" */),
  "component---src-pages-ref-test-js": () => import("./../../../src/pages/ref_test.js" /* webpackChunkName: "component---src-pages-ref-test-js" */),
  "component---src-pages-referral-js": () => import("./../../../src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-sentencing-js": () => import("./../../../src/pages/sentencing.js" /* webpackChunkName: "component---src-pages-sentencing-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-documentation-page-js": () => import("./../../../src/templates/documentation-page.js" /* webpackChunkName: "component---src-templates-documentation-page-js" */)
}

